// Private pages
import Withdrawal from '../pages/cashier/withdrawal';
import Deposit from '../pages/cashier/deposit';
import Error from '../pages/error';
import Expired from '../pages/expired';
import Success from '../pages/success';

const routes = [
	{ path: 'withdrawal', element: <Withdrawal /> },
	{ path: 'deposit', element: <Deposit /> },
	{ path: 'error', element: <Error /> },
	{ path: 'expired', element: <Expired /> },
	{ path: 'success', element: <Success /> },
];

const exportedRoutes = { routes };

export default exportedRoutes;