import { Grid, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';

// Local imports
import color from '../../styles/color';
interface WarningMessageContainerProps {
	text: string;
    hasRebate: boolean; // noRebate/rebate
    rebatePercentage?: number;
}

export const WarningMessageContainer: React.FC<WarningMessageContainerProps> = ({ text, hasRebate, rebatePercentage }) => {
    const { t } = useTranslation();
   
    const styles = {
        container: {
            position: 'relative', 
            backgroundColor: '#FEF1F2',
        },
        warningIcon: {
            color: color.rose.rose500, 
            fontSize: '16px', 
            marginLeft: 1, 
            marginRight: 1, 
            verticalAlign: 'sub',
            position: 'absolute', 
            left: 7, 
            top: 10,
        },
    }

    return (

        <Grid flexDirection="row" sx={styles.container} p={1} pl={5} alignItems={'center'} container justifyContent={'flex-start'} width={'100%'} borderRadius={'4px'} borderLeft={'3px solid #F43F5E'}>
            <WarningIcon sx={styles.warningIcon} />
            {
                !hasRebate ?
                //noRebate
                (
                    <Grid item md={12} xs={12}>
                        <Typography variant="body2" color={color.rose.rose500}>
                            {text}
                        </Typography>
                    </Grid>
                )
                :
                (
                //rebate
                    <>
                        <Grid item md={12} xs={12}>
                            <Typography variant="body2" color={color.rose.rose500}>
                                {t('withdrawGotRebate', {percent: rebatePercentage})}
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography variant="body2" color={color.rose.rose500}>
                                {t('confirmWithinTimeToGetBonus')}
                            </Typography>
                        </Grid>
                    </>
                )
            }
            
            
        </Grid>
            
    );
};

export default WarningMessageContainer;