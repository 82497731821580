import { Grid, Box, Typography, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import FlashOnOutlinedIcon from '@mui/icons-material/FlashOnOutlined';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dialog, DialogContent } from '@mui/material';
import * as React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

// Local imports
import color from '../../styles/color';
interface AlertModalProps {
    isOpen: boolean;
    hasRebate: boolean;
    isConfirmTransSuccess: boolean;
    callBack: () => void;
    close: () => void;
}


export const AlertModal: React.FC<AlertModalProps> = ({ isOpen, hasRebate, isConfirmTransSuccess, callBack, close }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
          children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
      ) {
        return <Slide direction="up" ref={ref} {...props} />;
      });

    const styles = {
        box: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '35%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '12px',
            padding: 3,
        },
        icon: {
            fontSize: '24px', 
            color: isConfirmTransSuccess ? color.emerald.emerald500 : color.rose.rose500, 
            padding:"5px", 
            borderRadius: '50px', 
            backgroundColor: isConfirmTransSuccess ? color.emerald.emerald50 : color.rose.rose50, 
            width: '40px', 
            height: '40px', 
            marginLeft: 1,
            marginRight: 1, 
            verticalAlign: 'sub',
        },
        button: {
            paddingLeft: '12vh', 
            paddingRight: '12vh', 
            paddingTop: '8px', 
            paddingBottom: '8px', 
            borderRadius: '12px', 
            backgroundColor: color.sky.sky500,
        },
        mbIcon: {
            fontSize: '24px', 
            color: isConfirmTransSuccess ? color.emerald.emerald500 : color.rose.rose500, 
            padding:"5px", 
            borderRadius: '50px', 
            backgroundColor: isConfirmTransSuccess ? color.emerald.emerald50 : color.rose.rose50, 
            width: '60px', 
            height: '60px', 
            marginLeft: 1, 
            marginRight: 1, 
            verticalAlign: 'sub',
        },
        mbButton: {
            width: '100%', 
            padding: '10px', 
            borderRadius: '12px', 
            backgroundColor: color.sky.sky500,
        },
    };

    return (
        <>
            {!isMobile ? (
                // PC view
                <Modal onClose={close} open={isOpen} aria-describedby="modal-modal-description">
                    <Box sx={styles.box}>
                        <Grid container flexDirection={'column'} alignItems={'center'}>
                            <Grid item mb={2}>
                                {
                                    isConfirmTransSuccess ?
                                        <FlashOnOutlinedIcon sx={styles.icon} />
                                    :
                                        <CloseIcon sx={styles.icon} />
                                }
                            </Grid>
                            <Grid item mb={2}>
                                <Typography variant='h6' fontWeight={'bold'}>
                                    {isConfirmTransSuccess ? t('Completed') : t('SystemError')}
                                </Typography>
                            </Grid>
                            <Grid item mb={2}>
                                <Typography variant='body2' color={color.slate.slate500} textAlign={'center'}>
                                    {isConfirmTransSuccess ? ( hasRebate ? t('AlertModalDesc1') : t('YouCompleteConfirmReceived') ) : t('AlertModalDesc2')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container flexDirection={'row'} justifyContent={'center'}>
                            <Grid item>
                                <Button onClick={callBack} variant="contained" style={styles.button}>
                                    <Typography
                                        variant='body1'
                                        sx={{ textTransform: 'none' }} // Apply the style here
                                    >
                                        {isConfirmTransSuccess ? t('BackToWithdraw') : t('TryAgain')}
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            ) 
            :
            // Mobile view
            (
                <Dialog
                    fullScreen={isMobile}
                    open={isOpen}
                    onClose={close}
                    TransitionComponent={Transition}
                >
                    <DialogContent sx={{paddingLeft: 2, paddingRight: 2}}>
                        <Grid container height={'100%'} direction={'column'} justifyContent={'space-around'} alignItems={'center'}>
                            <Grid container flexDirection={'column'} alignItems={'center'}>
                                <Grid item mb={2}>
                                    {
                                        isConfirmTransSuccess ?
                                            <FlashOnOutlinedIcon sx={styles.mbIcon} />
                                        :
                                            <CloseIcon sx={styles.mbIcon} />
                                    }
                                </Grid>
                                <Grid item mb={2}>
                                    <Typography variant='h6' fontWeight={'bold'} fontSize={'28px'}>
                                        {isConfirmTransSuccess ? t('Completed') : t('SystemError')}
                                    </Typography>
                                </Grid>
                                <Grid item mb={2}>
                                    <Typography variant='body1' color={color.slate.slate500} textAlign={'center'}>
                                        {isConfirmTransSuccess ? ( hasRebate ? t('AlertModalDesc1') : t('YouCompleteConfirmReceived') ) : t('AlertModalDesc2')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container flexDirection={'row'} justifyContent={'center'}>
                                <Grid item width={'100%'}>
                                    <Button onClick={callBack} variant="contained" style={styles.mbButton}>
                                        <Typography
                                            variant='body1'
                                            sx={{ textTransform: 'none' }} // Apply the style here
                                        >
                                            {isConfirmTransSuccess ? t('BackToWithdraw') : t('TryAgain')}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            )}
        </>

    );
};

export default AlertModal;