// External imports
import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

// Local imports
import initI18n from './lang';
import App from './app/index';
import theme from './styles/theme';
import './index.css'; // Import the CSS file

// Global initialization
initI18n();

const htmlRoot = document.getElementById('root') as HTMLElement;
const reactRoot = ReactDOM.createRoot(htmlRoot);



reactRoot.render(
	process.env.REACT_APP_SCRICT_MODE === 'true' ? (
		<StrictMode>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</BrowserRouter>
		</StrictMode>
	) : (
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</BrowserRouter>
	),
);