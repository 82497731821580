import * as AxiosUtil from '../utils/axiosUtil';
import { GetSplitwithdrawalResult } from '../apiIntegration/models/withdrawal/getWithdrawal';

const withdrawalApiPath = 'api/splitwithdrawal';

//get function
export function getSplitwithdrawal(token: string) {
	return AxiosUtil.getCashierWebApiInstance(withdrawalApiPath, token)
    .get('landing')
    .then((response) => {
        return response.data as GetSplitwithdrawalResult;
    });
}

//put function
export function confirmSplitwithdrawal(splitWithdrawalOrderId: string, token: string) {
	return AxiosUtil.getCashierWebApiInstance(withdrawalApiPath, token)
    .put('confirm', {splitWithdrawalOrderId: splitWithdrawalOrderId})
    .then((response) => {
        return response;
    });
}