import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';

// Local imports
import color from '../../styles/color';

const ExpiredPage: React.FC = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            textAlign="center"
            bgcolor="#f8f9fa"
            padding={isMobile ? 2 : 4} // Adjust padding for mobile or desktop
        >
            <div style={{backgroundColor: '#13CE66', padding: '3vh', borderRadius: 100, marginBottom: 20}}> 
                <DoneIcon sx={{color: '#FFF', fontSize: '60px'}} />
            </div>
            <Typography
                variant={isMobile ? 'h5' : 'h4'} // Smaller text for mobile
                gutterBottom
                color={color.black}
            >
                {t('PaymentSuccess')}
            </Typography>
        </Box>
    );
};

export default ExpiredPage;
