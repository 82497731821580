import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const ErrorPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
      bgcolor="#f8f9fa"
      padding={isMobile ? 2 : 4} // Adjust padding for mobile or desktop
    >
      <Typography
        variant={isMobile ? 'h5' : 'h4'} // Smaller text for mobile
        color="error"
        gutterBottom
      >
        Opps, something went wrong.
      </Typography>
      <Typography
        variant={isMobile ? 'body2' : 'body1'} // Smaller description for mobile
        color="textSecondary"
        gutterBottom
      >
        Please contact the IT support or try again later.
      </Typography>
    </Box>
  );
};

export default ErrorPage;
