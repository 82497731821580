// External imports
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Local imports
import routes from './path';

// Component definition
function App() {
	return (
		<Routes>
			{
				publicroutes
			}
		</Routes>
	);
}

// interface Route {
// 	path: string;
// 	element: React.ReactNode;
// }

const publicroutes = (
	routes.routes.map((route: {
		path: string;
		element: React.ReactNode;
	}) => (
		<Route key={route.path} path={route.path} element={route.element} />
	))
)

// Default export
export default App;

