import * as AxiosUtil from '../utils/axiosUtil';
import { GetDepositResult } from '../apiIntegration/models/deposit/getDeposit';
import { FileData } from '../apiIntegration/models/deposit/confirmDeposit';

const depositApiPath = 'api/deposit';

//get function
export function getDeposit(token: string) {
	return AxiosUtil.getCashierWebApiInstance(depositApiPath, token)
    .get('')
    .then((response) => {
        return response.data as GetDepositResult;
    });
}

//put function
export function confirmDeposit(token: string, depositOrderId: number, files: FileData[]) {
	return AxiosUtil.getCashierWebApiInstance(depositApiPath, token)
    .put('confirm', {
        depositOrderId: depositOrderId,
        files: files,
    })
    .then((response) => {
        return response;
    });
}