import { Grid, Box, Typography, Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dialog, DialogContent } from '@mui/material';
import * as React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';

// Local imports
import color from '../../styles/color';
interface ConfirmTransactionModalProps {
    isOpen: boolean;
    callBack: () => void;
    close: () => void;
}

export const ConfirmTransactionModal: React.FC<ConfirmTransactionModalProps> = ({ isOpen, callBack, close }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
    const [countdown, setCountdown] = React.useState<number>(5); // Countdown starts at 5 seconds
    const [isFirstOpen, setIsFirstOpen] = React.useState(true);

    React.useEffect(() => {
        // If the countdown reaches 0, enable the button
        if (countdown === 0) {
            setIsDisabled(false);
            return;
        }

        // Otherwise, set a timeout to decrease the countdown every second
        const timer = setTimeout(() => {
            setCountdown(countdown - 1);
        }, 1000);

        // Clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, [countdown]);

    React.useEffect(() => {
        if (isOpen) {
            if (isFirstOpen) {
                setIsFirstOpen(false);
            }
        }
    }, [isOpen, isFirstOpen]);

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
          children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const styles = {
        box: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '45%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '12px',
            paddingTop: 3,
            paddingBottom: 3,
        },
        icon: {
            fontSize: '24px', 
            color: color.rose.rose500, 
            padding:"5px", 
            borderRadius: '50px', 
            backgroundColor: color.rose.rose50, 
            width: '40px', 
            height: '40px', 
            marginLeft: 1, 
            marginRight: 1, 
            verticalAlign: 'sub',
        },
        button: {
            paddingTop: '8px', 
            paddingBottom: '8px', 
            borderRadius: '12px', 
            backgroundColor: isDisabled ? color.slate.slate500 : color.sky.sky500,
            color: isDisabled ? color.white : '',
            width: '100%',
        },
        backButton: {
            paddingTop: '8px', 
            paddingBottom: '8px', 
            borderRadius: '12px', 
            // backgroundColor: isDisabled ? color.slate.slate500 : color.sky.sky500,
            color: color.slate.slate900,
            borderColor: color.slate.slate300,
            width: '100%',
        },
        mbIcon: {
            fontSize: '24px', 
            color: color.rose.rose500, 
            padding:"5px", 
            borderRadius: '50px', 
            backgroundColor: color.rose.rose50, 
            width: '60px', 
            height: '60px', 
            marginLeft: 1, 
            marginRight: 1, 
            verticalAlign: 'sub',
        },
        mbButton:{
            width: '100%', 
            padding: '10px', 
            borderRadius: '12px', 
            backgroundColor: isDisabled ? color.slate.slate500 : color.sky.sky500,
            color: isDisabled ? color.white : '',
        },
        mbBackButton:{
            width: '100%', 
            padding: '10px', 
            borderRadius: '12px', 
            color: color.slate.slate900,
            borderColor: color.slate.slate300,
        },
    };

    return (
        <>
            {!isMobile ? (
                // PC view
                <Modal onClose={close} open={isOpen} aria-describedby="modal-modal-description">
                    <Box sx={styles.box}>
                        <Grid container flexDirection={'column'} alignItems={'center'} p={2}>
                            <Grid item mb={2}>
                                <WarningIcon sx={styles.icon} />
                            </Grid>
                            <Grid item mb={2}>
                                <Typography variant='h6' fontWeight={'bold'}>
                                    {t('Alert')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2' color={color.slate.slate500}>
                                    {t('ConfirmTransModalDesc1')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container p={2} mb={2} flexDirection={'column'} alignItems={'center'} bgcolor={color.amber.amber50}>
                            <Grid item>
                                <Typography variant='body2'>
                                    {t('ConfirmTransModalDesc2')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>
                                    {t('ConfirmTransModalDesc3')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>
                                    {t('ConfirmTransModalDesc4')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container flexDirection={'row'}>
                            <Grid item xs={6} md={6} pl={2} pr={1}>
                                <Button onClick={close} variant="outlined" style={styles.backButton}>
                                    <Typography
                                        variant='body1'
                                        sx={{ textTransform: 'none' }} // For displaying small letter case in English
                                    >
                                        {t('notRecieve')}
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={6} pl={1} pr={2}>
                                <Button onClick={callBack} variant="contained" style={styles.button} disabled={isDisabled}>
                                    <Typography
                                        variant='body1'
                                        sx={{ textTransform: 'none' }} // For displaying small letter case in English
                                    >
                                        {isDisabled ? `${t('ConfirmRecieved')} (${countdown})` : t('ConfirmRecieved')}
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            )
            : 
            // Mobile view
            (
                <Dialog
                    fullScreen={isMobile}
                    open={isOpen}
                    onClose={close}
                    TransitionComponent={isFirstOpen ? Transition : undefined} // Apply transition only on first open
                >
                    <DialogContent style={{paddingLeft: 0, paddingRight: 0}}>
                        <Grid container height={'100%'} direction={'column'} justifyContent={'space-around'} alignItems={'center'}>
                            <Grid container flexDirection={'column'} alignItems={'center'} p={2}>
                                <Grid item mb={2}>
                                    <WarningIcon sx={styles.mbIcon} />
                                </Grid>
                                <Grid item mb={2}>
                                    <Typography variant='h6' fontWeight={'bold'} fontSize={'28px'}>
                                        {t('Alert')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1' color={color.slate.slate500} textAlign={'center'}>
                                        {t('ConfirmTransModalDesc1')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container p={2} mb={2} flexDirection={'column'} alignItems={'center'} bgcolor={color.amber.amber50}>
                                <Grid item>
                                    <Typography variant='body1' textAlign={'center'}>
                                        {t('ConfirmTransModalDesc2')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1' textAlign={'center'}>
                                        {t('ConfirmTransModalDesc3')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1' textAlign={'center'}>
                                        {t('ConfirmTransModalDesc4')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container flexDirection={'row'} justifyContent={'center'}>
                                <Grid item width={'100%'} mr={2} ml={2}>
                                    <Button onClick={callBack} variant="contained" style={styles.mbButton} disabled={isDisabled}>
                                        <Typography
                                            variant='body1'
                                            sx={{ textTransform: 'none' }} // Apply the style here
                                        >
                                            {isDisabled ? `${t('ConfirmRecieved')} (${countdown})` : t('ConfirmRecieved')}
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item width={'100%'} mr={2} ml={2} mt={2}>
                                    <Button onClick={close} variant="outlined" style={styles.mbBackButton}>
                                        <Typography
                                            variant='body1'
                                            sx={{ textTransform: 'none' }} // Apply the style here
                                        >
                                            {t('notRecieve')}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default ConfirmTransactionModal;