import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface SimpleBackdropProps {
	backDropOpen: boolean;
	backgroundColor: string;
	progressColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
}

export const SimpleBackDrop: React.FC<SimpleBackdropProps> = ({ backDropOpen, backgroundColor, progressColor }) => {

	return (
		<div>
			<Backdrop
				sx={{ color: backgroundColor, zIndex: (theme) => theme.zIndex.drawer + 10000000 }}
				open={backDropOpen}
			>
				{progressColor && <CircularProgress color={progressColor} />}
			</Backdrop>
		</div>
	);
};

export default SimpleBackDrop;
