import axios, { AxiosInstance } from 'axios';
import * as ConfigUtil from '../utils/configUtil';

export const getCashierWebApiInstance = (controllerPath: string, token: string) => {
	const instance = axios.create({
		baseURL: ConfigUtil.getRodimusCashierWebApiBaseUrl() + '/' + controllerPath,
		timeout: 60000,
	});
	setupAxiosInterceptor(instance, token);
	return instance;
}

const setupAxiosInterceptor = (axiosInstance: AxiosInstance, token: string): void => {
  axiosInstance.interceptors.request.use((request) => {
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // Simply reject the promise for any errors without token refreshing
      return Promise.reject(error);
    }
  );
};