import { createTheme } from '@mui/material/styles';
import color from './color';
 
const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#075985',
//     },
//     secondary: {
//       main: '#075985',
//     },
//   },
  components: {
    MuiTypography: {
        styleOverrides: {
            h5: {
                color: color.sky.sky50
            }
        },
    },
    MuiDivider: {
        styleOverrides: {
            root: {
                color: color.sky.sky200
            }
        },
    },
  },
});

export default theme;